import React from 'react'

import { HtmlLayoutProps } from './interface'

interface Props {
  layout: HtmlLayoutProps
}
export function HtmlWidget({ layout }: Props) {
  return layout.data ? (
    <div className="faq-html">
      <Iframe content={layout.data} />
    </div>
  ) : null
}

interface IframeProps {
  content?: string
}
function Iframe(props: IframeProps) {
  const writeHTML = (frame: HTMLIFrameElement | null) => {
    if (!props.content || !frame || !frame.contentDocument) {
      return
    }
    const doc = frame.contentDocument
    frame.style.height = '0px'
    doc.open()
    doc.write(props.content)
    doc.close()
    frame.style.width = '100%'
    frame.style.height = `${frame.contentWindow?.document.body.scrollHeight}px`
  }
  return <iframe src="about:blank" className="html-widget-iframe" ref={writeHTML} />
}
