import React from 'react'

import { Link, GatsbyLinkProps } from 'gatsby'

import { QAHeader, QAPanel, QAContent, values } from '@karakuri-ai/faq-component'

import { Data, ImportantLayoutProps } from './interface'

type Props = { data: Data; layout: ImportantLayoutProps }
export const ImportantWidget: React.FC<Props> = ({ layout, data }) => {
  const importants = values(data.settingYaml.importants, [])
  if (!importants || importants.length === 0) {
    return null
  }
  return (
    <QAPanel className="faq-panel-important">
      <QAHeader title={`${layout.title || '重要なご質問'}`} />
      {importants.map(({ id, title }) => {
        if (!id || !title) {
          return null
        }
        const LinkComponent = React.forwardRef<
          HTMLAnchorElement,
          GatsbyLinkProps<Record<string, unknown>>
        >(function LinkComponent(props, ref) {
          return <Link ref={ref as any} {...props} to={`/answer/${id}?important=true`} />
        })
        return <QAContent key={id} question={title} LinkComponent={LinkComponent} />
      })}
    </QAPanel>
  )
}
