import React from 'react'

import { IframeLayoutProps } from './interface'

interface Props {
  layout: IframeLayoutProps
}
export function IframeWidget({ layout }: Props) {
  return layout.data ? <iframe className={'faq-iframe'} src={layout.data} /> : null
}
