import React from 'react'

import { Link } from 'gatsby'

import {
  Breadcrumb as BreadcrumbComponent,
  TagCloud as TagCloudComponent,
} from '@karakuri-ai/faq-component'

import { SettingYamlCategorySub } from '../graphql'
import { traverse } from '../utils/breadcrumb'
import { Breadcrumb, TagCloud, Hierarchy } from './interface'

interface Props {
  breadcrumb?: Breadcrumb
  tagCloud?: TagCloud
  relatedCategories?: SettingYamlCategorySub[]
  hierarchies: Hierarchy[]
  dictionary: Record<string, SettingYamlCategorySub>
  topTitle: string
}
export function BreadcrumbWidget({
  breadcrumb,
  tagCloud,
  relatedCategories,
  hierarchies,
  dictionary,
  topTitle,
}: Props) {
  const r = relatedCategories || []
  const tags = tagCloud
    ? [
        { link: '/', title: topTitle },
        ...r.map(c => {
          const title = c.title || ''
          const link = traverse(c, dictionary, [{ id: c.id || '', title }])
            .reverse()
            .reduce<string[]>(
              (acc, h) => {
                acc.push(h.id)
                return acc
              },
              ['/search']
            )
            .join('/')
          return { link, title }
        }),
      ]
    : []

  const LinkComponent = React.forwardRef<HTMLAnchorElement, React.ComponentProps<typeof Link>>(
    function LinkComponent(props: React.ComponentProps<typeof Link>, ref) {
      const found = hierarchies.findIndex(h => h.link === props.to)
      const h = hierarchies.slice(0, found)
      const newProps: Omit<React.ComponentProps<typeof Link>, 'to'> = {}
      if (h.length > 0) {
        newProps.state = { hierarchies: h }
      }
      return <Link ref={ref as any} {...{ ...props, ...newProps }} />
    }
  )
  return (
    <div className="faq-search-navigation">
      {!tagCloud && breadcrumb && (
        <BreadcrumbComponent {...breadcrumb} topTitle={topTitle} LinkComponent={LinkComponent} />
      )}
      {tagCloud && <TagCloudComponent tags={tags} LinkComponent={LinkComponent} />}
    </div>
  )
}
