import React from 'react'

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Link as MuiLink,
} from '@material-ui/core'
import clsx from 'clsx'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'

import { QAHeader, QAPanel, values } from '@karakuri-ai/faq-component'

import { IconLayoutProps } from './interface'

type LinkProps = GatsbyLinkProps<unknown>
const Link: React.FC<LinkProps> = ({ children, to, activeClassName, partiallyActive }) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <MuiLink
        component={GatsbyLink}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        color="textSecondary"
        underline="none"
      >
        {children}
      </MuiLink>
    )
  }
  return (
    <MuiLink href={to} target="_blank" rel="noreferrer" color="textSecondary" underline="none">
      {children}
    </MuiLink>
  )
}
function referenceTo(reference?: string) {
  if (!reference) {
    return ''
  }
  if (reference.indexOf('http') === 0) {
    return reference
  }
  if (reference.indexOf('-') === -1) {
    return `/answer/${reference}`
  }
  return `/category/${reference}`
}
type Props = { layout: IconLayoutProps }
export function IconWidget({ layout }: Props) {
  return (
    <QAPanel className="faq-panel-icon">
      <QAHeader title={`${layout.title}`} />
      <div className="faq-panel-card-list">
        {values(layout.icons).map(icon => {
          const reference = icon.reference || ''
          const to = referenceTo(reference)
          return (
            <Card
              key={`${icon.id}`}
              className={clsx('faq-panel-card', { 'faq-panel-noimage': !icon.image })}
            >
              <CardActionArea component="div">
                <Link to={to}>
                  <CardContent className="faq-panel-card-content">
                    <Typography variant="h5">{icon.title}</Typography>
                  </CardContent>
                  {icon.image && (
                    <CardMedia
                      className="faq-panel-card-media"
                      component="img"
                      image={icon.image}
                    />
                  )}
                </Link>
              </CardActionArea>
            </Card>
          )
        })}
      </div>
    </QAPanel>
  )
}
