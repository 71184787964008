type SendEventType = {
  faqEventCategory: string
  faqEventAction: string
  faqEventLabel?: string
  faqEventValue?: string
}

export class KarakuriAnalyticsEvent {
  static search(inputValue: string, queryFAQ: any[]) {
    const searchResultCount = queryFAQ ? queryFAQ.length : 0
    if (searchResultCount === 0) {
      this.sendEvent({
        faqEventCategory: 'keywordSearch',
        faqEventAction: 'searchResultZero',
        faqEventLabel: inputValue,
        faqEventValue: '1',
      })
    }
    this.sendEvent({
      faqEventCategory: 'keywordSearch',
      faqEventAction: 'searchExecute',
      faqEventLabel: inputValue,
      faqEventValue: '1',
    })
  }

  static onFilterSearch(inputValue: string, count: number) {
    if (count === 0) {
      this.sendEvent({
        faqEventCategory: 'keywordSearch',
        faqEventAction: 'searchResultZero',
        faqEventLabel: inputValue,
        faqEventValue: '1',
      })
    }
    this.sendEvent({
      faqEventCategory: 'keywordSearch',
      faqEventAction: 'searchExecute',
      faqEventLabel: inputValue,
      faqEventValue: '1',
    })
  }

  static feedback(feedback: 'ok' | 'ng') {
    this.sendEvent({
      faqEventCategory: 'feedback',
      faqEventAction: feedback === 'ok' ? 'OK' : 'NG',
      faqEventValue: '1',
    })
  }

  static sendEvent({
    faqEventCategory,
    faqEventAction,
    faqEventLabel,
    faqEventValue,
  }: SendEventType) {
    const type = 'faq'
    const analyticsSendEventTimer = setInterval(function () {
      if (window.krkr) {
        window.krkr(type, {
          faqEventCategory,
          faqEventAction,
          faqEventLabel,
          faqEventValue,
        })
        clearInterval(analyticsSendEventTimer)
      }
    }, 100)
  }
}
