import React, { useCallback, useMemo } from 'react'

import { useLazyQuery, gql } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import { Link, GatsbyLinkProps, PageProps } from 'gatsby'
import debounce from 'just-debounce'
import Mustache from 'mustache'

import { QAHeader, QAPanel, QAContent, Pagination } from '@karakuri-ai/faq-component'

import { onFilterSearch } from '../utils/gtm'
import { KarakuriAnalyticsEvent } from '../utils/karakuri-analytics-event'
import {
  Data,
  FilterSearchResultLayoutProps,
  SearchPageContext,
  Hierarchy,
  SearchParams,
  CategoryDictionary,
} from './interface'

const SEARCH = gql`
  query Search($input: FAQFilterSearchInputV2!) {
    search: searchV2(input: $input) {
      currentPage
      perPage
      total
      totalPages
      data {
        id
        title
        confidence
      }
    }
  }
`
interface Card {
  id: string
  title: string
  confidence?: number
}
interface SearchResult {
  currentPage: number
  perPage: number
  total: number
  totalPages: number
  data: Card[]
}
type Props = Pick<
  PageProps<Data, SearchPageContext, SearchParams>,
  'data' | 'location' | 'pageContext'
> & {
  layout: FilterSearchResultLayoutProps
  dictionary: CategoryDictionary
  q: string[]
  page: number
  onSearch: (q: string[], page: number) => void
  botId: string
}
export function FilterSearchResultWidget({
  layout,
  dictionary,
  q,
  page,
  onSearch,
  pageContext,
  location,
  botId,
  data,
}: Props) {
  const waitMs = data.settingYaml.waitMs?.search || 500
  const nrOfItemsTemplate =
    data.settingYaml.words?.search?.numberOfItems ||
    '{{total}}件のうち {{start}}-{{end}}件 を表示しています'

  const maybeThisOneThreshold = data.settingYaml.maybeThisOneThreshold || 1
  const [callSearch, { loading, data: searchResult }] = useLazyQuery<{ search: SearchResult }>(
    SEARCH,
    { fetchPolicy: 'no-cache' }
  )
  const [results, setResults] = React.useState(searchResult?.search?.data || [])
  const [count, setCount] = React.useState(searchResult?.search?.totalPages || 1)
  const memoizedSearch = useMemo(
    () =>
      debounce(
        (q: string[], page: number) =>
          callSearch({
            variables: {
              input: {
                botId,
                q,
                page,
                tags: pageContext?.category?.id
                  ? [pageContext.category.id, ...pageContext.children]
                  : [],
                searchType: data.settingYaml.searchType,
                queryThreshold: data.settingYaml.queryThreshold || undefined,
              },
            },
          }),
        waitMs,
        false,
        true
      ),
    [
      botId,
      callSearch,
      data.settingYaml.queryThreshold,
      data.settingYaml.searchType,
      pageContext?.category?.id,
      pageContext?.children,
      waitMs,
    ]
  )
  const search = useCallback(
    (q: string[], page: number) => memoizedSearch(q, page),
    [memoizedSearch]
  )
  React.useEffect(() => {
    search(q, page)
    // onSearch(q, page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, page])
  React.useEffect(() => {
    if (searchResult?.search?.data) {
      setResults(searchResult.search.data)
      setCount(searchResult.search.totalPages)
      onFilterSearch(q.join(' '), searchResult.search.total)
      KarakuriAnalyticsEvent.onFilterSearch(q.join(' '), searchResult.search.total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult?.search?.data])

  const title = pageContext.category?.title
    ? `${pageContext.category?.title}のご質問`
    : layout.title || '検索結果'
  return (
    <QAPanel>
      {loading && (
        <div className="faq-loading">
          <CircularProgress />
        </div>
      )}
      <QAHeader title={title} />
      {numberOfItems(nrOfItemsTemplate, searchResult?.search)}
      {results.map(({ id, title, confidence }) => {
        const LinkComponent = React.forwardRef<HTMLAnchorElement, GatsbyLinkProps<SearchParams>>(
          function LinkComponent(props, ref) {
            return (
              <Link<SearchParams>
                ref={ref as any}
                {...props}
                to={`/answer/${id}?search=true`}
                state={{
                  hierarchies: location.pathname
                    .split('/')
                    .map(p => (dictionary || {})[p])
                    .filter(c => !!c)
                    .reduce<Hierarchy[]>((acc, c) => {
                      const current = acc.length === 0 ? '/search' : acc[acc.length - 1].link
                      acc.push({ title: c.title || '', link: `${current}/${c.id}` })
                      return acc
                    }, []),
                  q,
                  page,
                  tags: pageContext?.category?.id
                    ? [pageContext.category.id, ...pageContext.children]
                    : [],
                }}
              />
            )
          }
        )
        return (
          <QAContent
            key={id}
            question={title}
            LinkComponent={LinkComponent}
            className={(confidence || 0) > maybeThisOneThreshold ? 'maybe-this-one' : ''}
          />
        )
      })}
      {count > 1 && <Pagination page={page} count={count} onChange={page => onSearch(q, page)} />}
    </QAPanel>
  )
}

function numberOfItems(template: string, search?: SearchResult) {
  if (!search) {
    return null
  }
  const total = search.total
  if (!total) {
    return null
  }
  const base = (search.currentPage - 1) * search.perPage
  const start = base + 1
  const end = Math.min(total, base + search.perPage)
  return <p className="faq-number-of-items">{Mustache.render(template, { total, start, end })}</p>
}
