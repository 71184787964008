import React, { useCallback, useMemo } from 'react'

import { useLazyQuery, gql } from '@apollo/client'
import { navigate, PageProps } from 'gatsby'
import debounce from 'just-debounce'
import uniq from 'just-unique'

import { FilterSearch } from '@karakuri-ai/faq-component'

import { BreadcrumbWidget } from './BreadcrumbWidget'
import { Data, FilterSearchLayoutProps, SearchParams } from './interface'

const COMPLETE = gql`
  query Complete($input: FAQCompleteInput!) {
    complete(input: $input)
  }
`
const SUGGEST = gql`
  query Suggest($input: FAQSuggestInput!) {
    suggest(input: $input)
  }
`
function canAutoSearch(useAutoSearch: boolean, location: Props['location'], q: string[]) {
  if (
    !useAutoSearch ||
    (location.pathname !== '/search' && location.pathname !== '/search/' && q.length === 0)
  ) {
    return false
  }
  return true
}

function usePlaceholder(defaultPlaceholder: string, q: string[]) {
  const [placeholder, setPlaceholder] = React.useState('')
  React.useEffect(() => {
    if (defaultPlaceholder.startsWith('[hide]')) {
      const p = defaultPlaceholder.slice(6)
      setPlaceholder(q.length > 0 ? '' : p)
    } else {
      setPlaceholder(defaultPlaceholder)
    }
  }, [defaultPlaceholder, q])
  return placeholder
}

type Props = Pick<PageProps<Data, Record<string, unknown>, SearchParams>, 'data' | 'location'> & {
  layout: FilterSearchLayoutProps
  tags: string[]
  q: string[]
  breadcrumbWidgetProps?: React.ComponentProps<typeof BreadcrumbWidget>
  onSearch?: (q: string[], page?: number) => void
}
export function FilterSearchWidget({
  layout,
  data,
  location,
  // tags,
  q,
  onSearch,
  breadcrumbWidgetProps,
}: Props) {
  const botId = data.settingYaml.botId
  const waitMs = data.settingYaml.waitMs?.complete || 500
  const useAutoSearch = data.settingYaml.useAutoSearch || false
  const useSearchWithCategory = data.settingYaml.useSearchWithCategory || false
  const useKnowledgeSearch = data.settingYaml.useKnowledgeSearch || false

  const [callComplete, { data: completeResult }] = useLazyQuery<{ complete: string[] }>(COMPLETE)
  const [callSuggest, { data: suggestResult }] = useLazyQuery<{ suggest: string[] }>(SUGGEST)
  const [options, setOptions] = React.useState(completeResult?.complete || [])
  const [keywords, setKeywords] = React.useState(suggestResult?.suggest || [])
  const placeholder = usePlaceholder(data.settingYaml?.words?.search?.placeholder || '', q)
  const input = React.useRef('')

  const memoizedComplete = useMemo(
    () =>
      debounce(
        (keyword: string) => {
          if (!useKnowledgeSearch) {
            return
          }
          // TODO tagsの量が多いとcloudfrontのリクエスト最大長を超える場合がある。現在、非使用。
          callComplete({ variables: { input: { botId, keyword, q, tags: [] } } })
        },
        waitMs,
        false,
        true
      ),
    [botId, callComplete, q, waitMs, useKnowledgeSearch]
  )
  const complete = useCallback((keyword: string) => memoizedComplete(keyword), [memoizedComplete])
  React.useEffect(() => {
    complete('')
    if (!useKnowledgeSearch) {
      return
    }
    // TODO tagsの量が多いとcloudfrontのリクエスト最大長を超える場合がある。現在、非使用。
    callSuggest({ variables: { input: { botId, q, tags: [] } } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId, q, useKnowledgeSearch])
  React.useEffect(() => {
    if (suggestResult?.suggest) {
      setKeywords(suggestResult?.suggest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestResult?.suggest])
  React.useEffect(() => {
    if (completeResult?.complete) {
      setOptions(completeResult?.complete)
    }
  }, [completeResult])
  const handleSearch = React.useCallback(
    (event?: React.FormEvent) => {
      if (event) {
        event.preventDefault()
      }
      if (input.current && !q.includes(input.current)) {
        handleSelect(input.current)
        input.current = ''
        return
      }
      if (onSearch) {
        if (
          !useSearchWithCategory &&
          location.pathname !== '/search' &&
          location.pathname !== '/search/'
        ) {
          navigate('/search/?q=' + q.join(' '), { state: { q, page: 1 } })
          return
        }
        onSearch(q)
        return
      }
      navigate('/search/?q=' + q.join(' '), { state: { q, page: 1 } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [q]
  )
  React.useEffect(() => {
    if (canAutoSearch(useAutoSearch, location, q)) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])

  const handleInput = (value: string) => {
    if (value) {
      complete(value)
    }
    input.current = value
  }
  const handleChange = (newValue: string[]) => {
    if (onSearch) {
      onSearch(newValue, 1)
      return
    }
    navigate('/search/?q=' + newValue.join(' '), { state: { q: newValue, page: 1 } })
  }
  const handleSelect = (value: string) => {
    const newValue = uniq([...q, value])
    if (onSearch) {
      onSearch(newValue, 1)
      return
    }
    navigate('/search/?q=' + newValue.join(' '), { state: { q: newValue, page: 1 } })
  }

  return (
    <form onSubmit={handleSearch}>
      <div className="faq-panel faq-filter-search">
        <h3 className="faq-panel-title">{layout.title || 'キーワード検索'}</h3>
        <FilterSearch
          title={data.settingYaml?.words?.search?.message || ''}
          onSearch={handleSearch}
          keywordProps={{
            keywords,
            title: data.settingYaml?.words?.search?.keyword || '',
            onSelect: handleSelect,
          }}
          searchProps={{
            value: q,
            placeholder,
            options,
            onInput: handleInput,
            onChange: handleChange,
          }}
        />
      </div>
      {breadcrumbWidgetProps && <BreadcrumbWidget {...breadcrumbWidgetProps} />}
    </form>
  )
}
