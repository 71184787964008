import React from 'react'

import { Link, GatsbyLinkProps } from 'gatsby'

import { QAHeader, QAPanel, QAContent } from '@karakuri-ai/faq-component'

import { Layout } from '../graphql'
import { Related } from './interface'

export interface RelatedLayoutProps extends Layout {
  type: 'related'
}
interface Props {
  related: Related[]
  layout: RelatedLayoutProps
}
export function RelatedWidget({ related, layout }: Props) {
  return related.length > 0 ? (
    <QAPanel>
      <QAHeader title={layout.title || '関連するよくあるご質問'} />
      {related.map(({ id, title }) => {
        const LinkComponent = React.forwardRef<
          HTMLAnchorElement,
          GatsbyLinkProps<Record<string, unknown>>
        >(function LinkComponent(props, ref) {
          return <Link ref={ref as any} {...props} to={`/answer/${id}`} />
        })
        return <QAContent key={id} question={title} LinkComponent={LinkComponent} />
      })}
    </QAPanel>
  ) : null
}
